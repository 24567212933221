.tab-bar {
  // Ionic tab icons are a bit larger than they should be.
  // Let's force them to be smaller.
  ion-icon {
    font-size: 22px;
  }

  ion-label {
    //padding-top: 0.2em;
  }

  ion-tab-bar {
    padding-top: 3px;
  }
}

// Give it some space at the bottom on Android
.md ion-tabs {
  padding-bottom: 3px;
}

