.app-wrapper {
  ion-button.button-small::part(native) {
    height: 2rem;
  }

  ion-footer {
    padding-bottom: var(--ion-safe-area-bottom);
    padding-top: calc(var(--ion-padding) / 2);
  }

  p, ion-text {
    line-height: 1.5;
  }
}

hr {
  display: block;
  height: 1px;
  width: 100%;
  background-color: var(--ion-color-light-shade);
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.italic {
  font-style: italic;
}

ion-input {
  --placeholder-opacity: 0.2 !important;
}

.md ion-searchbar {
  --background: var(--ion-color-light);
  --box-shadow: none;
  --border-radius: 5px;

  .searchbar-input-container input {
    border: 1px solid var(--ion-color-light-shade);
  }
}

table {
  border: 1px solid var(--ion-color-light);
  font-size: 0.9em;
  tr:not(:last-child) td {
    border-bottom: 1px solid var(--ion-color-light);
  }
  td:not(:last-child) {
    border-right: 1px solid var(--ion-color-light);
  }
  td {
    padding: 3px 6px;
  }
}

.dark {
  ion-list {
    background: none; // --background var not supported here
  }

  ion-item {
    --background: none;
  }

  ion-chip:not(.chip-outline) {
    background: rgba(var(--ion-color-base-rgb), 0.3);
    border: 1px solid rgba(var(--ion-color-base-rgb), 0.3);
    color: var(--ion-color-base);
  }

  ion-chip.chip-outline {
    background: rgba(var(--ion-color-base-rgb), 0.2);
    border-color: rgba(var(--ion-color-base-rgb), 0.2);
  }

  ion-searchbar {
    // Make searchbar lighter on dark mode
    --background: var(--ion-color-light);
  }

  ion-popover {
    --background: var(--ion-color-light);

    ion-item {
      --background: var(--ion-color-light);
    }
  }
}
