#welcome-page {
  margin-top: var(--ion-safe-area-top);
  margin-bottom: calc(var(--ion-padding) / 2);

  max-width: 400px !important;
  margin-left: auto !important;
  margin-right: auto !important;

  .swiper {
    display: grid;

    .swiper-slide {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-end;

      ion-grid {
        width: 100%;
      }
    }
  }

  .img-wrapper {
    padding: calc(var(--ion-padding) * 2);
    padding-bottom: var(--ion-padding);

    svg {
      display: block;
      max-height: 30vh;
      max-width: 40vh;
      margin: auto;

      #person {
        animation: float 5s ease-in-out infinite;
      }
    }
  }

  p {
    font-size: 1.2em;
    margin: var(--ion-padding);
    color: var(--ion-color-medium);
  }

  h1 {
    font-size: 1.9em;
    font-weight: 600;
  }

  .btn-email,
  .btn-google,
  .btn-apple {
    svg {
      margin-right: 0.3em;
      width: 1.1em;
    }

    span {
      display: inline-block;
      width: 100%;
    }
  }

  .btn-google {
    --color: #fff;
    --background: #4285F4;
    --background-hover: #427bdb;
    --background-activated: #427bdb;

    svg {
      height: 0.9em;
    }
  }

  .btn-apple {
    --color: #fff;
    --background: #000;
    --background-hover: #222;
    --background-activated: #222;

    svg {
      height: 1.1em;
    }
  }

  .btn-email {
    --color: #fff;
    --background: #000;
    --background-hover: #222;
    --background-activated: #222;

    svg {
      height: 1em;
    }
  }

  .btn-back {
    text-align: left;
    margin-right: auto;
    margin-left: calc(-1 * var(--ion-padding));
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-4%);
  }
  100% {
    transform: translatey(0px);
  }
}
