#subscribe-page {
  .product-row {
    margin-left: var(--ion-padding);
    margin-right: var(--ion-padding);
    display: flex;
    flex-direction: row;

    ion-card {
      width: 100%;
      margin-left: calc(var(--ion-padding) / 2);
      margin-right: calc(var(--ion-padding) / 2);
      border: 1px solid rgba(var(--ion-color-primary-rgb), 0.5);
      background: none;
      position: relative;
      overflow: visible;
      margin-top: var(--ion-padding);

      ion-badge {
        position: absolute;
        top: -1em;
        right: -0.8em;
        padding: 0.4em 1em;
        font-size: 0.9em;
        text-shadow: 0 0 2px rgba(var(--ion-color-dark-rgb), 0.6);
      }

      ion-card-header {
        padding-right: 0; // To prevent text from wrapping on smaller screens
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .price {
      font-size: 1.3rem;
    }

    .period {
      font-size: 0.8rem;
    }
  }
}
