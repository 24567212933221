@import 'src/util';

.book-grid-item {
  width: calc(100% / 3);
  margin-bottom: var(--ion-padding);
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: var(--ion-color-light);
  }

  & > div {
    margin: calc(var(--ion-padding) / 2);
  }

  .book-cover {
    padding-top: calc(1 / var(--cover-aspect) * 100%);
  }

  h2 {
    @include ellipsis;
    font-size: 0.9rem;
    font-weight: 700;
    margin: 8px 0 4px 0;
  }

  h3 {
    @include ellipsis;
    font-size: 0.8rem;
    margin: 4px 0;
    color: var(--ion-color-medium);
  }

  .meta {
    @include ellipsis;
    color: var(--ion-color-medium);
    font-size: 0.8rem;
    margin: 0;
    min-width: 0;
  }

  @media (min-width: 576px) {
    width: calc(100% / 4);
  }

  @media (min-width: 768px) {
    // One less due to sidebar being visible
    width: calc(100% / 5);
  }

  @media (min-width: 992px) {
    width: calc(100% / 6);
  }

  @media (min-width: 1200px) {
    width: calc(100% / 7);
  }

  @media (min-width: 1500px) {
    //width: calc(100% / 8);
  }

  @media (min-width: 1700px) {
    //width: calc(100% / 9);
  }

  &.size-small {
    width: calc(100% / 4);

    h2 {
      font-size: 0.7rem;
      margin: 0.3em 0;
    }

    h3 {
      font-size: 0.6rem;
      margin: 0.3em 0;
    }

    .meta {
      font-size: 0.6rem;
    }

    @media (min-width: 576px) {
      width: calc(100% / 5);
    }

    @media (min-width: 768px) {
      width: calc(100% / 6);
    }

    @media (min-width: 992px) {
      width: calc(100% / 7);
    }
  }

  &.size-tiny {
    width: calc(100% / 6);

    h2 {
      font-size: 0.6rem;
    }

    h3 {
      font-size: 0.5rem;
    }

    .meta {
      display: none;
    }

    @media (min-width: 576px) {
      width: calc(100% / 7);
    }

    @media (min-width: 768px) {
      width: calc(100% / 8);
    }
  }
}
