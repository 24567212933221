.dev-only {
  display: block;
  border: 1px solid red;
  border-radius: 0.2rem;
  position: relative;
  &::after {
    pointer-events: none;
    position: absolute;
    top: 0.1rem;
    right: 0.2rem;
    content: "dev only";
    font-size: 0.4em;
    color: red;
  }
}
