.home-books-section {
  ion-item.heading {
    // Remove padding from heading
    --padding-start: 0;
  }

  .horizontal {
    overflow-x: auto;
    width: 100%;

    display: flex;
    flex-direction: row;

    .book-grid-item {
      width: 110px;
      min-width: 110px;
      box-sizing: content-box;

      &.size-small {
        min-width: 90px;
        width: 90px;
      }

      &.size-tiny {
        min-width: 70px;
        width: 70px;
      }

      &:first-child {
        margin-left: calc(var(--ion-padding) / -2);
      }

      &:last-child {
        padding-right: var(--ion-padding);
      }
    }
  }
}
