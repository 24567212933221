@import 'src/util';

.book-list-item {
  .book-cover {
    height: var(--cover-height, 80px);
    width: calc(var(--cover-height, 80px) * var(--cover-aspect));
    margin-top: calc(var(--ion-padding) / 2);
    margin-bottom: calc(var(--ion-padding) / 2);

    // Make status smaller on book covers
    .status-overlay {
      font-size: 0.4rem;
      line-height: 1.6em;
    }
  }

  &.size-small .book-cover {
    --cover-height: 55px;
  }

  .add-button {
    margin: 0 calc(-1 * var(--ion-padding) / 2) 0 0;

    // Make button full height and center its children vertically
    height: 100%;
    display: flex;
    align-items: center;

    // Make the add button as large as possible
    --padding-start: var(--ion-padding);
    --padding-end: var(--ion-padding);
    --padding-top: 2rem;
    --padding-bottom: 2rem;
  }

  ion-label {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  h2 {
    font-weight: 600 !important;
    margin: 0;
  }

  .meta {
    @include ellipsis;
    color: var(--ion-color-medium);
    font-size: 0.8rem;
    margin: 0;
    min-width: 0;
  }

  .date {
    float: right;
    align-items: center;
    display: flex;
  }

  ion-note {
    font-size: 15px;
    margin-right: 8px;
    font-weight: normal;
  }

  ion-note.md {
    margin-right: 14px;
  }

  .dot {
    display: block;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    align-self: start;
    margin: 16px 10px 16px 16px;
  }

  .dot-unread {
    background: var(--ion-color-primary);
  }

  ion-footer ion-title {
    font-size: 11px;
    font-weight: normal;
  }

  &.size-small {
    h3 {
      font-size: 0.8rem;
    }

    h3 {
      font-size: 0.8rem;
    }

    .meta {
      font-size: 0.7rem;
    }
  }
}
