.book-cover-stack {
  position: relative;
  height: 100%;

  .book-cover {
    position: absolute;

    &:nth-child(1) {
      left: 0;
      z-index: 10;
    }

    &:nth-child(2) {
      z-index: 9;
      left: 15px;
      transform: scale(0.9);
    }

    &:nth-child(3) {
      z-index: 8;
      left: 28px;
      transform: scale(0.8);
    }
  }

  &.size-small .book-cover {
    &:nth-child(1) {
      z-index: 10;
    }

    &:nth-child(2) {
      left: 10px;
    }

    &:nth-child(3) {
      left: 19px;
    }
  }
}
