.book-list-list-item {
  --default-height: 80px;

  &.size-small {
    --default-height: 45px;
  }

  ion-item::part(native) {
    height: calc(var(--default-height) + var(--ion-padding));
  }

  .book-cover-stack {
    height: var(--default-height);
    width: calc(var(--default-height) * var(--cover-aspect) + 45px);
  }

  .book-cover {
    height: var(--default-height);
    width: calc(var(--default-height) * var(--cover-aspect));

    // Make status smaller on book covers
    .status-overlay {
      font-size: 0.4rem;
      line-height: 1.6em;
    }
  }

  h2 {
    font-weight: 600 !important;
    margin: 0;
  }

  ion-note {
    font-size: 15px;
    margin-right: 8px;
    font-weight: normal;
  }
}
