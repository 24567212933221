@import '../../util';

.book-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  background-color: var(--ion-color-light);
  box-shadow: 1px 2px 6px rgba(var(--ion-color-light-contrast-rgb), 0.3);
  position: relative;

  &, .border-overlay, .status-overlay {
    border-radius: 4px;
  }

  .border-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    border: 1px solid;

    // Set global opacity to cover border and shadow
    opacity: 0.7;
  }

  .status-overlay {
    @include ellipsis;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 0.5rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    padding: 0 0.4em;
    line-height: 1.8em;
    text-transform: uppercase;
    text-shadow: 0 0 0.4em rgba(0, 0, 0, 0.4);
    text-align: center;
    white-space: nowrap;
  }

  svg {
    display: block;
    position: absolute;
    top: -3%;
    right: -5%;
    height: auto;
    width: 25%;
    min-width: 10px;
    max-width: 20px;

    // For some reason this prevents clipping on iOS
    transform: scale(1);

    .fill-fg {
      fill: var(--ion-color-light);
    }

    .fill-bg {
      fill: var(--ion-color-success);
    }
  }
}
