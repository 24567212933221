#home-page {
  .reading-slides .slick-dots {
    bottom: 0;

    li {
      margin: 0;
    }

    li button:before {
      color: var(--ion-color-primary);
      font-size: 0.7rem;
    }
  }
}
