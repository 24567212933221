@import '../../util';

.button-select {
  ion-button::part(native) {
    padding: var(--ion-padding);
  }

  ion-button {
    .select-icon {
      position: relative;
      padding-right: var(--ion-padding);

      .select-icon-inner {
        left: 5px;
        top: 50%;
        margin-top: -2px;
        position: absolute;
        width: 0;
        height: 0;
        border-top: 5px solid;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        color: currentColor;
        pointer-events: none;
      }
    }
  }
}

// Must be outside because of portals
.button-select-alert {
  .alert-radio-group,
  .alert-checkbox-group {
    max-height: 40vh;
  }
}
