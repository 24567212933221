#login-page {
  margin-bottom: calc(var(--ion-padding) / 2);

  ion-toolbar {
    --border-style: none;
  }

  h1 {
    font-size: var(--font-size-lg);
  }
}
