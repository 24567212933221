@import 'src/util';

.book-showcase {
  padding-left: var(--ion-padding);
  padding-right: var(--ion-padding);
  width: 100%;

  &.button {
    cursor: pointer;

    &:hover {
      background-color: var(--ion-color-light);
    }
  }

  .grid {
    align-items: center;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-gap: calc(var(--ion-padding));
  }

  .book-cover-container {
    width: calc(100vw / 3);
    max-width: 150px;

    .book-cover {
      width: 100%;
      padding-top: calc(1 / var(--cover-aspect) * 100%);
    }
  }

  .title {
    //@include ellipsis;
    margin: 0;
    font-size: 1.2rem;
  }

  .authors {
    @include ellipsis;
    display: block;
    font-size: 0.9em;
  }

  .tags {
    margin-top: var(--ion-padding);
    margin-bottom: 0;
  }
}

