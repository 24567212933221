.book-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: calc(var(--ion-padding) / 2);
  max-width: 1200px;

  .book-grid-break {
    width: 100%;
    margin-left: calc(var(--ion-padding) / 2);
    margin-right: calc(var(--ion-padding) / 2);
    display: flex;
    align-items: center;
  }
}
