.carousel {
  overflow: hidden;
  position: relative;
}

.carousel__container {
  display: flex;
}

.carousel__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.carousel__dots {
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0 0 0.5rem;

  button {
    background-color: transparent;
    padding: 0.4rem;
  }

  li button:before {
    opacity: 0.3;
    color: var(--ion-color-primary);
    font-size: 0.5rem;
    content: '⬤';
  }

  li.carousel__dots--active button:before {
    opacity: 1;
  }
}
