#book-details-page {
  --cover-height: 200px;

  .book-cover-container {
    padding-top: var(--ion-padding);

    .book-cover {
      height: var(--cover-height, 80px);
      width: calc(var(--cover-height, 80px) * var(--cover-aspect));
      margin: var(--ion-padding) auto;
    }
  }
}
